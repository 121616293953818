import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    "&.MuiButton-root": {
      fontSize: 14,
      fontWeight: 600,
      textTransform: "none",
      color: "#EB363F",
      "&:hover": {
        background: "none",
      },
    },
  },
}));

export function RedLinkButton(props) {
  const classes = useStyles();
  const { children, onClick } = props;

  return (
    <Button className={classes.root} onClick={onClick}>
      {children}
    </Button>
  );
}

RedLinkButton.propTypes = {
  onClick: PropTypes.func,
};
