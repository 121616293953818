import React from "react";
import { useRoutes, Navigate } from "react-router-dom";
import MainRoutes from "./MainRoutes";
import LoginRoutes from "./LoginRoutes";
import { useAuth } from "../../contexts";

export function Main() {
  const { isLoggedIn } = useAuth();

  return useRoutes([
    {
      path: "/",
      element: <Navigate to={"/home"} replace />,
    },
    LoginRoutes,
    MainRoutes,
  ]);
}
