import "./App.css";
import React, { Fragment, useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { AuthProvider, ToastProvider } from "./contexts";
import {
  Main,
  SubscribePlan,
  BuyMagazine,
  EmailVerified,
  LinkExpired,
} from "./pages";
import theme from "./theme";

const theme1 = createTheme({});

function App() {
  useEffect(() => {
    console.log("🚀 ~ Web App version: 1.3 (26 June 2024)");
  }, []);

  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <ToastProvider>
            <Router>
              <Main />
            </Router>
          </ToastProvider>
        </AuthProvider>
      </ThemeProvider>

      {/* Subscribe payment */}
      <ThemeProvider theme={theme}>
        <ToastProvider>
          <Router>
            <Routes>
              {/* <Route path="/" element={<Navigate to="/plans" replace />} /> */}
              {/* <Route path="/plans" element={<SubscribePlan />} /> */}
              <Route path="/plans/:userID" element={<SubscribePlan />} />
              <Route
                path="/buy-magazine/:userID/:magID"
                element={<BuyMagazine />}
              />
              <Route
                path="/email-verified"
                element={<EmailVerified title="Email Verified" />}
              />
              <Route
                path="/link-expired"
                element={<LinkExpired title="Link Expired" />}
              />
              <Route
                path="/payment-success"
                element={<EmailVerified title="Payment Successfull" />}
              />
              <Route
                path="/payment-failed"
                element={<LinkExpired title="Payment Failed" />}
              />
            </Routes>
          </Router>
        </ToastProvider>
      </ThemeProvider>
    </Fragment>
  );
}

export default App;
