import { Paper, Typography, Stack, Grid, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { PaymentButton } from "../../components";

const useStyles = makeStyles((theme) => ({
  root: {
    "&.MuiPaper-root": {
      padding: theme.spacing(3),
      borderRadius: theme.spacing(1),
    },
  },
  container: {
    display: "flex",
    justifyContent: "center",
  },
  tickImage: {
    width: 25,
    height: 25,
  },
  boldText: {
    "&.MuiTypography-root": {
      fontSize: 24,
      fontWeight: 700,
    },
  },
  mediumText: {
    "&.MuiTypography-root": {
      fontSize: 16,
      fontWeight: 500,
    },
  },
  redText: {
    "&.MuiTypography-root": {
      fontSize: 16,
      fontWeight: 500,
      color: theme.palette.common.redTxt,
    },
  },
  tickContainer: {
    display: "flex",
    alignItems: "center",
    width: 130,
  },
}));

const getDuration = (days) => {
  switch (days) {
    case 365:
      return "Year";
    case 180:
      return "Half Year";
    case 30:
      return "Month";
    default:
      return "-";
  }
};

export function PlanCard(props) {
  const classes = useStyles();
  const { id, price, days, name, buttonClick, hideBtn, disabled, btnTitle } =
    props;

  return (
    <Paper className={classes.root} key={id}>
      <Grid container direction="row">
        <Grid item xs>
          <Stack spacing={1}>
            <Stack direction="row" alignItems="center">
              <Typography
                className={classes.boldText}
              >{`₹ ${price}/`}</Typography>
              <Typography className={classes.mediumText}>
                {getDuration(days)}
              </Typography>
            </Stack>
            <Typography className={classes.redText}>{name}</Typography>
          </Stack>
        </Grid>
        {hideBtn && (
          <Grid item className={classes.tickContainer}>
            <PaymentButton onClick={() => buttonClick(id)} disabled={disabled}>
              {btnTitle}
            </PaymentButton>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}
