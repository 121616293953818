import { Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CategoryCard, TitleBar } from "../../components";
import { useNavigate, useLocation } from "react-router-dom";
import { api, urls } from "../../services";
import { useState } from "react";
import { useEffect } from "react";
import { useToast } from "../../contexts";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(0),
      paddingTop: theme.spacing(2.5),
    },
  },

  containerSecound: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingRight: theme.spacing(0),
    // background: "#F6F6F6",
    minHeight: "70vh",
  },
}));

//<!-----------------------sample Data------------------>

export function CategoryList(props) {
  const { onItemClick } = props;
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const { showToast } = useToast();

  const getCategories = () => {
    api
      .get(urls.categories)
      .then((res) => setCategories(res.data.data))
      .catch((err) => {
        showToast(err.response?.data?.message, "error");
      });
  };
  useEffect(() => {
    getCategories();
  }, []);

  const handleOptionClick = (id) => {
    navigate(`/category-details/${id}`, {
      replace: location.pathname.includes("category-details"),
    });
    onItemClick();
  };

  return (
    <Grid container className={classes.containerSecound}>
      <Container maxWidth="xl" className={classes.root}>
        <Grid
          container
          className={classes.container}
          spacing={1}
          direction="row"
        >
          {/* <Grid item md={12} xs={12} sm={12}>
            <TitleBar Title="Categories" />
          </Grid> */}
          {categories.map((category, i) => (
            <Grid key={i} item onClick={() => handleOptionClick(category.id)}>
              <CategoryCard
                primaryText={category.descriptions[0].category_name}
                color={category.category_colour}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Grid>
  );
}

CategoryList.propTypes = {
  onItemClick: PropTypes.func,
};
