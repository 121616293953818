const menuOptions = [
  {
    type: 1,
    title: "Home",
    icon: "ri-home-4-line",
    isSelected: true,
    link: "/home",
    mageMenu: false,
  },
  {
    type: 2,
    title: "Magazines",
    icon: "ri-book-open-line",
    isSelected: false,
    link: "/magazines",
    mageMenu: false,
  },

  {
    type: 3,
    title: "Categories",
    icon: "ri-list-check",
    isSelected: false,
    link: "/categories",
    mageMenu: true,
  },
  {
    type: 4,
    title: "Plans",
    icon: "ri-vip-crown-2-line",
    isSelected: false,
    link: "/plans",
    mageMenu: false,
  },
];

export { menuOptions };
