import React, { useState, useEffect } from "react";
import { Container, Grid, Stack, Link, alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import {
  ArticleCard,
  TitleBar,
  BreadCrumbList,
  BottomBanner,
} from "../../components";
import { api, urls } from "../../services";
import { useToast, useAuth } from "../../contexts";
import LoaderCard from "../../components/cards/LoaderCard";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(0),
      paddingTop: theme.spacing(2.5),
    },
  },

  containerSecound: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingRight: theme.spacing(0),
    [theme.breakpoints.down("sm")]: {},
    background: "#F6F6F6",
    minHeight: "70vh",
  },
}));

//<!-----------------------sample Data------------------>

export function ArticleList() {
  const navigate = useNavigate();
  const [articleData, setArticleData] = useState(null);
  const { showToast } = useToast();
  const classes = useStyles();
  const { logout } = useAuth();
  const [apiCalling, setApiCalling] = useState(false);

  const getArticle = () => {
    api
      .get(urls.trendingArticles)
      .then((res) => {
        setArticleData(res.data.data);
        setApiCalling(false);
      })
      .catch((err) => {
        setApiCalling(false);
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const handleArticleClick = (id) => {
    navigate(`/article-details/${id}`);
  };

  useEffect(() => {
    setApiCalling(true);
    getArticle();
  }, []);

  return (
    <Grid container className={classes.containerSecound}>
      <Container maxWidth="xl" className={classes.root}>
        <BreadCrumbList pathnames={[{ text: "Articles", link: "" }]} />
        <Grid
          container
          className={classes.container}
          spacing={2}
          direction="row"
        >
          <Grid item md={12} xs={12} sm={12}>
            <TitleBar Title="Trending Articles" />
          </Grid>
          {apiCalling && (
            <Grid item md={12} xs={12} sm={12}>
              <LoaderCard />
            </Grid>
          )}
          {articleData?.articles?.map((post, i) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={i}>
                <Stack>
                  <Link
                    href={`article-details/${post.id}`}
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <ArticleCard
                      iconPrimary={post.feature_image}
                      primary={post.title}
                      secondary={post.author.name}
                      authorImage={post.author.image}
                      secondarySub={
                        post.article_categories[0].descriptions[0].category_name
                      }
                      color={post.article_categories[0].category_colour}
                      backgroundColor={alpha(
                        post.article_categories[0].category_colour,
                        0.1
                      )}
                    />
                  </Link>
                </Stack>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Grid>
  );
}
