import PropTypes from "prop-types";
import React, { useState } from "react";
// material-ui
import { makeStyles, useTheme } from "@mui/styles";
import {
  Grid,
  Typography,
  Avatar,
  Box,
  IconButton,
  Stack,
} from "@mui/material";

import { exportIcon, Vector, vuesax } from "../assets";
// style constant
const useStyles = makeStyles((theme) => ({
  imageContainer: {
    "&.MuiGrid-root": {
      position: "relative",
    },
    paddingLeft: 8,
    paddingRight: 8,
  },
  floatedContainer: {
    "&.MuiGrid-root": {
      position: "absolute",
      top: "20%",
      right: "20%",
      background: "white",
      borderRadius: "20px",
      display: "flex",
      justifyContent: "space-between",
    },
  },
  headerImage: {
    width: "20px",
  },
  cardImage: {
    width: "100%",
    // maxHeight: "500px",
    // width: "-webkit-fill-available",
    // padding: "0px",
    borderRadius: "5px;",
    objectFit: "cover",
    aspectRatio: "4/2.5",
  },
}));

// =============================|| SIDE ICON CARD ||============================= //

export const ArticleImage = ({ feature_image, onClickBookmark, caption }) => {
  const classes = useStyles();
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  return (
    <Grid
      item
      xs={12}
      className={classes.imageContainer}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <Stack spacing={1}>
        <img className={classes.cardImage} alt="image" src={feature_image} />
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 400,
            color: "#565656",
            fontFamily: "Baloo Chettan 2",
          }}
        >
          {caption}
        </Typography>
      </Stack>

      {/* {isHovering && (
        <>
          <Grid className={classes.floatedContainer}>
            <IconButton
              // onClick={ter}
              className={classes.rightSideIcon}
              sx={{ display: { xs: "none", md: "flex" }, mr: 1, ml: 1 }}
            >
              <img className={classes.headerImage} alt="Vector" src={Vector} />
            </IconButton>
            <IconButton
              onClick={onClickBookmark}
              className={classes.rightSideIcon}
              sx={{ display: { xs: "none", md: "flex" }, mr: 1, ml: 1 }}
            >
              <img
                className={classes.headerImage}
                alt="Bookmark"
                src={vuesax}
              />
            </IconButton>
            <IconButton
              // onClick={ter}
              className={classes.rightSideIcon}
              sx={{ display: { xs: "none", md: "flex" }, mr: 1, ml: 1 }}
            >
              <img
                className={classes.headerImage}
                alt="export"
                src={exportIcon}
              />
            </IconButton>
          </Grid>
        </>
      )} */}
    </Grid>
  );
};

ArticleImage.propTypes = {
  feature_image: PropTypes.string,
  onClickBookmark: PropTypes.func,
};
