import { Fragment } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../contexts";

export function AuthRoute(props) {
  const { isLoggedIn } = useAuth();

  return (
    <Fragment>
      {isLoggedIn ? <Navigate to="/home" replace /> : props.children}
    </Fragment>
  );
}

export function MainRoute(props) {
  const { isLoggedIn } = useAuth();

  return (
    <Fragment>
      {isLoggedIn ? props.children : <Navigate to="/signin" replace />}
    </Fragment>
  );
}
