import { Snackbar, Alert } from "@mui/material";
import PropTypes from "prop-types";

export function Toast(props) {
  const { open, severity, message, onClose } = props;

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={onClose}
    >
      <Alert
        severity={severity}
        sx={{ width: "100%" }}
        variant="filled"
        elevation={4}
        onClose={onClose}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

Toast.propTypes = {
  open: PropTypes.bool,
  severity: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
};
