import React, { useState, Fragment } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";
import { logo, menu, search, bookmark, likeShapes } from "../../assets";
import { menuOptions } from "../../utilities";
import { useNavigate } from "react-router-dom";
import { Grid, Stack, Avatar, Badge } from "@mui/material";
import { CategoryList } from "../../pages";

import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useAuth } from "../../contexts";

const useStyles = makeStyles((theme) => ({
  appbar: {
    "&.MuiAppBar-root": {
      backgroundColor: "#ffffff",
      boxShadow: "0px",
      borderBottom: "1px solid #e0e0e0",
    },
  },
  logoContainer: {
    height: 70,
    justifyContent: "center",
    alignItems: "center",
    display: { xs: "none", md: "flex" },
  },
  logoImage: {
    width: 250,
    height: 50,
    cursor: "pointer",
  },
  logoImageXs: {
    width: 150,
    height: 30,
    cursor: "pointer",
  },
  rightSideIcon: {
    backgroundColor: "#F2F2F2 !important",
    "&.MuiIconButton-root": {
      height: "fit-content",
    },
  },
  buttonItem: {
    "&.MuiButton-root": {
      "&:hover": {
        color: "black",
        backgroundColor: "white",
      },
    },
  },
  popoverPaper: {
    width: "100%",
    maxWidth: "100% !important",
    top: "72px !important",
    left: "0px !important",
    boxShadow: "0px 9px 15px #d1d1d1",
    minHeight: " 200px !important",
    borderRadius: "0px !important",
  },
  toolbar: {
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
    },
  },
  leftMenu: {
    display: "flex",
  },
  menuImage: {
    "&.MuiIconButton-root": {
      padding: "0 10px 0px 0px !important",
    },
  },
  headerImage: {
    width: "18px",
    [theme.breakpoints.down("md")]: {
      width: "17px",
    },
  },
  badge: {
    "&.MuiBadge-root": {
      "&.MuiBadge-badge": {
        minWwidth: "17px",
        padding: "0 3px",
        height: "17px",
        fontSize: "10px",
        top: "-6px",
        right: "-6px",
        backgroundColor: "#E91F29",
      },
    },
  },
  customBadge: {
    minWidth: "17px !important",
    padding: "0 3px",
    height: "17px !important",
    fontSize: "10px !important",
    top: "-6px !important",
    right: "-6px !important",
    backgroundColor: "#E91F29 !important",
  },
  userImage: {
    width: "17px",
    position: "absolute",
    zIndex: "1000",
    right: "0",
    top: "12px",
    [theme.breakpoints.down("md")]: {
      width: "17px",
    },
  },
  sideMenu: {
    "&.MuiMenu-root": {
      "& .MuiPaper-root": {
        width: "100%",
        boxShadow: "none",
        left: "0 !important",
        maxWidth: "100%",
        height: "100%",
      },
    },
  },
}));

//---------------customised button --------->
const ColorButton = styled(Button)(({ theme }) => ({
  borderRadius: "4px",
  backgroundColor: "#EB363F",
  textTransform: "none",
  fontSize: 13,
  fontWeight: 700,
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "#d33039",
  },
}));

const OutlinedButton = styled(Button)(({ theme }) => ({
  borderRadius: "4px",
  color: "#EB363F",
  border: "1px solid #EB363F",
  textTransform: "none",
  fontSize: 13,
  fontWeight: 700,
  "&:hover": {
    border: "1px solid #d33039",
    color: "#d33039",
  },
}));
export function Header() {
  const classes = useStyles();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { isLoggedIn, logout, userData } = useAuth();
  // const [isSearch, setSearch] = React.useState(null);
  const handleSearch = () => {
    navigate("/search-articles");
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMobileMenuOptionClick = (index) => {
    setAnchorElNav(null);
    setOptions((prev) => {
      const curr = [...prev];
      curr.map((c) => (c.isSelected = false));
      curr[index].isSelected = true;
      return curr;
    });
    navigate(options[index].link);
  };

  const [options, setOptions] = useState(menuOptions);
  const navigate = useNavigate();

  const handleOptionClick = (i) => {
    setOptions((prev) => {
      const curr = [...prev];
      curr.map((c) => (c.isSelected = false));
      curr[i].isSelected = true;
      return curr;
    });
    navigate(options[i].link);
  };
  const handleOpenBookMark = (i) => {
    navigate("/bookmarks");
  };
  const handleOpenAccount = (i) => {
    navigate("/account");
    setAnchorElUser(null);
  };
  //-------------------category menu

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMobile = (event) => {
    setAnchorElNav(null);
    setAnchorEl(event.currentTarget);
  };

  //Modal ----------------------------------------------
  const [openModal, setOpenModal] = React.useState(false);

  const handleClickOpen = () => {
    setOpenModal(true);
    setAnchorElUser(null);
  };
  const handleClickClose = () => {
    setOpenModal(false);
  };
  //Modal ----------------------------------------------

  const handleSignInClick = () => {
    navigate("/signin");
  };

  function stringAvatar(name) {
    if (!name) return "- -"; // Return "NA" for an empty string
    const names = name.split(" ");
    if (names.length === 1) return `${names[0].charAt(0)}`; // Handle single word
    return `${names[0].charAt(0)}${names[1].charAt(0)}`; // Handle multiple words
  }

  return (
    <>
      <AppBar position="static" className={classes.appbar} elevation={0}>
        <Container maxWidth="xl">
          <Toolbar disableGutters className={classes.toolbar}>
            <Box className={classes.leftMenu}>
              <Box
                className={classes.logoContainer}
                sx={{
                  display: {
                    xs: "none",
                    md: "flex",
                    padding: "0px 70px 0px 0px",
                  },
                  mr: 1,
                }}
              >
                <img
                  className={classes.logoImage}
                  alt="logo"
                  src={logo}
                  onClick={() => handleOptionClick(0)}
                />
              </Box>
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="black"
                  className={classes.menuImage}
                >
                  <img alt="menu" src={menu} />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                  className={classes.sideMenu}
                >
                  <MenuItem
                    divider={true}
                    sx={{ justifyContent: "space-between" }}
                  >
                    <Typography
                      textAlign="center"
                      sx={{
                        fontSize: "17px",
                        color: "black",
                        fontWeight: "600",
                      }}
                    >
                      Menu
                    </Typography>
                    <HighlightOffIcon onClick={handleCloseNavMenu} />
                  </MenuItem>
                  {options.map((m, i) =>
                    m.mageMenu && isLoggedIn ? (
                      <Fragment key={i}>
                        <MenuItem
                          key={i}
                          divider={true}
                          onClick={handleClickMobile}
                        >
                          <Typography textAlign="center">{m.title}</Typography>
                        </MenuItem>

                        <Menu
                          PopoverClasses={{ paper: classes.popoverPaper }}
                          className={classes.menupaper}
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          elevation={50}
                        >
                          <CategoryList onItemClick={() => handleClose()} />
                        </Menu>
                      </Fragment>
                    ) : m.type === 1 || m.type === 4 || isLoggedIn ? (
                      <Fragment>
                        <MenuItem
                          key={i}
                          divider={true}
                          onClick={() => handleMobileMenuOptionClick(i)}
                        >
                          <Typography textAlign="center">{m.title}</Typography>
                        </MenuItem>
                      </Fragment>
                    ) : null
                  )}
                </Menu>
              </Box>
              <Box
                className={classes.logoContainer}
                sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}
              >
                <img
                  className={classes.logoImageXs}
                  alt="logo"
                  src={logo}
                  onClick={() => handleOptionClick(0)}
                />
              </Box>
              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                {options.map((m, i) => {
                  return (
                    <Fragment key={i}>
                      {m.mageMenu && isLoggedIn ? (
                        <>
                          <Button
                            key={i}
                            sx={{
                              my: 2,
                              color: "#1A1A1A",
                              textTransform: "none",
                              display: "flex",
                              fontSize: "16px",
                            }}
                            className={classes.buttonItem}
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}
                            endIcon={
                              open ? (
                                <ExpandLessIcon sx={{ color: "#787878" }} />
                              ) : (
                                <ExpandMoreIcon sx={{ color: "#787878" }} />
                              )
                            }
                          >
                            {m.title}
                          </Button>
                          <Menu
                            PopoverClasses={{ paper: classes.popoverPaper }}
                            className={classes.menupaper}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                            elevation={50}
                          >
                            <CategoryList onItemClick={() => handleClose()} />
                          </Menu>
                        </>
                      ) : m.type === 1 || m.type === 4 || isLoggedIn ? (
                        <Button
                          key={i}
                          onClick={() => handleOptionClick(i)}
                          sx={{
                            my: 2,
                            color: "#1A1A1A",
                            textTransform: "none",
                            display: "flex",
                            fontSize: "16px",
                          }}
                          className={classes.buttonItem}
                        >
                          {m.title}
                        </Button>
                      ) : null}
                    </Fragment>
                  );
                })}
              </Box>
            </Box>
            <Stack direction={{ xs: "row", sm: "row" }}>
              {isLoggedIn ? (
                <Tooltip title="Search">
                  <IconButton
                    className={classes.rightSideIcon}
                    sx={{ mr: 2 }}
                    onClick={handleSearch}
                  >
                    <img
                      className={classes.headerImage}
                      alt="search"
                      src={search}
                    />
                  </IconButton>
                </Tooltip>
              ) : (
                <></>
              )}

              {isLoggedIn ? (
                <Fragment>
                  <Tooltip title="Bookmarks">
                    <IconButton
                      onClick={handleOpenBookMark}
                      className={classes.rightSideIcon}
                      sx={{ display: { md: "flex" }, mr: 2 }}
                    >
                      <Badge
                        badgeContent={0}
                        color="primary"
                        className={classes.badge}
                        classes={{ badge: classes.customBadge }}
                      >
                        <img
                          className={classes.headerImage}
                          alt="Bookmark"
                          src={bookmark}
                        />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Account">
                    {userData?.is_subscribed === true && (
                      <img
                        className={classes.userImage}
                        alt="like shapes"
                        src={likeShapes}
                      />
                    )}

                    <Avatar
                      onClick={handleOpenUserMenu}
                      sx={{
                        bgcolor: "black",
                        fontSize: "15px",
                        padding: "9px",
                        width: "15px",
                        height: "15px",
                        cursor: "pointer",
                      }}
                      aria-label="recipe"
                    >
                      {stringAvatar(userData?.first_name)}
                    </Avatar>
                  </Tooltip>
                </Fragment>
              ) : (
                <Fragment>
                  <Tooltip title="Sign In">
                    <ColorButton
                      fullWidth
                      variant="contained"
                      disableElevation
                      onClick={handleSignInClick}
                    >
                      Sign In
                    </ColorButton>
                  </Tooltip>
                </Fragment>
              )}
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                disableAutoFocusItem
              >
                <MenuItem onClick={handleOpenAccount} disableRipple>
                  <PermIdentityIcon sx={{ mr: 1 }} />
                  My Account
                </MenuItem>
                <MenuItem onClick={handleClickOpen} disableRipple>
                  <LogoutOutlinedIcon sx={{ mr: 1 }} />
                  Logout
                </MenuItem>
              </Menu>
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
      <Dialog open={openModal} onClose={handleClickClose}>
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            textAlign: "center",
            color: "black",
            fontWeight: "900",
          }}
          id="customized-dialog-title"
        >
          Logout
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} direction="row">
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "900",
                  color: "black",
                  textAlign: "center",
                }}
              >
                Are you sure want to logout?
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <OutlinedButton
                fullWidth
                variant="outlined"
                sx={{ height: "52px", borderRadius: "8px", fontSize: "16px" }}
                onClick={() => setOpenModal(false)}
              >
                Cancel
              </OutlinedButton>
            </Grid>
            <Grid item xs={6}>
              <ColorButton
                fullWidth
                variant="contained"
                disableElevation
                sx={{ height: "52px", borderRadius: "8px", fontSize: "16px" }}
                onClick={() => {
                  setOpenModal(false);
                  logout();
                  window.location.href = "/home";
                }}
              >
                Yes, logout
              </ColorButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
