import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button, FormHelperText, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate, useLocation } from "react-router-dom";
import { api, urls } from "../../services";
import { useToast } from "../../contexts";
import { useAuth } from "../../contexts";
//---------------components -------->
import {
  LoginWrapper,
  NormalTextField,
  EmailTextField,
} from "../../components";

const useStyles = makeStyles((theme) => ({
  titleText: {
    "&.MuiTypography-root": {
      fontWeight: 700,
      fontSize: "28px",
      color: "#1A1A1A",
    },
  },
  titleTextSub: {
    "&.MuiTypography-root": {
      fontWeight: 600,
      fontSize: 16,
      color: "#000",
      marginBottom: "5px;",
      marginTop: "15px;",
    },
  },
  linkText: {
    color: "#EB363F",
  },
}));
//---------------customised button --------->
const ColorButton = styled(Button)(({ theme }) => ({
  borderRadius: "8px",
  backgroundColor: "#EB363F",
  textTransform: "none",
  fontSize: 16,
  fontWeight: 700,
  marginTop: "30px !important",
  padding: "10px 16px !important",
  height: "52px",
  "&:hover": {
    backgroundColor: "#d33039",
  },
}));

//---------------customised checkbox --------->
const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  padding: "0 !important",
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#EB363F",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#EB363F",
  },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

export function SignInDetails() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { login, updateUserData } = useAuth();

  const [formData, setFormData] = useState({
    first_name: "",
    accept_terms: false,
    email: "",
    errors: { first_name: false, accept_terms: false, email: false },
  });
  const { showToast } = useToast();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const updateFormData = (key, value) => {
    setFormData((prev) => {
      const curr = { ...prev };
      curr[key] = value;
      curr.errors[key] = false;
      return curr;
    });
  };

  const handleCountinueClick = () => {
    let invalid = false;

    if (
      !formData.first_name ||
      formData.accept_terms === false ||
      !formData.email ||
      !validateEmail(formData.email)
    ) {
      setFormData((prev) => {
        return {
          ...prev,
          errors: {
            ...prev.errors,
            first_name: !formData.first_name,
            email: !formData.email || !validateEmail(formData.email),
            accept_terms: formData.accept_terms === false,
          },
        };
      });
      invalid = true;
    }

    if (!invalid) {
      const params = { ...formData };
      delete params.errors;
      api
        .post(urls.RegisterUser, params)
        .then((res) => {
          console.log("🚀 ~ .then ~ res.data:", res.data);
          let data = res.data;
          showToast(res.data.message, "success");
          updateUserData(data.data.user_data);
        })
        .catch((err) => {
          showToast(err.response?.data?.message, "error");
        });
    }
  };

  return (
    <LoginWrapper>
      <Stack
        direction={{ xs: "column", md: "column" }}
        spacing={{ xs: 1, sm: 1, md: 2 }}
      >
        <Typography
          className={classes.titleTextSub}
          gutterBottom
          variant="h5"
          component="div"
        >
          Enter Your Name
        </Typography>

        <EmailTextField
          label="Full Name"
          value={null}
          error={formData.errors.first_name}
          helperText={
            formData.errors.first_name ? "Enter your full name" : null
          }
          onChange={(e) => updateFormData("first_name", e.target.value)}
        />
        <Typography
          className={classes.titleTextSub}
          gutterBottom
          variant="h5"
          component="div"
        >
          Enter Your Email
        </Typography>
        <EmailTextField
          label="Email ID"
          value={null}
          error={formData.errors.email}
          helperText={formData.errors.email ? "Enter valid email" : null}
          onChange={(e) => updateFormData("email", e.target.value)}
        />
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ fontWeight: 400, fontSize: 16 }}
        >
          <BpCheckbox
            checked={formData.accept_terms}
            onChange={(e) =>
              updateFormData("accept_terms", !formData.accept_terms)
            }
          />
          I accept <span className={classes.linkText}>terms of use</span> and{" "}
          <span className={classes.linkText}>privacy policy.</span>
          {formData.errors.accept_terms && (
            <FormHelperText sx={{ color: "#d32f2f" }}>
              Please agree the Terms of use and Privacy policy
            </FormHelperText>
          )}
        </Typography>

        <ColorButton
          variant="contained"
          disableElevation
          fullWidth
          disableRipple
          onClick={() => handleCountinueClick()}
        >
          Continue
        </ColorButton>
      </Stack>
    </LoginWrapper>
  );
}
// ==============================|| Login PAGE ||============================== //
