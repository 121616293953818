import * as React from "react";
import PropTypes from "prop-types";

// material-ui
import { makeStyles, useTheme } from "@mui/styles";
import { Grid, Typography, Avatar, Box, Stack, alpha } from "@mui/material";
// style constant
const useStyles = makeStyles((theme) => ({
  primaryTextBlock: {
    "&.MuiTypography-root": {
      fontFamily: "Baloo Chettan 2",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "20px",
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
        lineHeight: "16px",
      },
    },
  },
  smallText: {
    "&.MuiTypography-root": {
      color: "#9F9F9F",
      fontFamily: "Urbanist",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 600,
      marginLeft: "8px",
      marginRight: "8px",
      [theme.breakpoints.down("md")]: {
        fontSize: "10px",
        lineHeight: "14px",
      },
    },
  },
  smallSubText: {
    "&.MuiTypography-root": {
      color: "#E91F29",
      fontFamily: "Baloo Chettan 2",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 500,
      [theme.breakpoints.down("md")]: {
        fontSize: "10px",
        lineHeight: "14px",
      },
    },
  },
  primaryText: {
    "&.MuiTypography-root": {
      marginLeft: "8px",
      fontWeight: 500,
      color: "#252525",
      fontFamily: "Baloo Chettan 2",
      fontSize: "16px",
      fontStyle: "normal",
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
      },
    },
  },
  headerAvatar: {
    "&.MuiAvatar-root": {
      width: "44px",
      height: "44px",
      [theme.breakpoints.down("md")]: {
        width: "32px",
        height: "32px",
      },
    },
  },
  premium: {
    "&.MuiTypography-root": {
      color: " #1A1A1A",
      fontFamily: "Baloo Chettan 2",
      fontSize: "40px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "52px",
      [theme.breakpoints.down("md")]: {
        fontSize: "24px",
        lineHeight: "32px",
      },
    },
  },
}));

// =============================|| SIDE ICON CARD ||============================= //

export const ArticleHeader = ({
  category_name,
  color,
  title,
  author_name,
  author_image,
  book_number,
  year_number,
  read_time,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Grid
      container
      direction="column"
      spacing={1}
      alignItems="flex-start"
      mb={3}
    >
      <Grid
        item
        sm={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box
          style={{
            backgroundColor: alpha(color, 0.1),
            borderRadius: "6px",
            padding: "7px 15px",
          }}
        >
          <Typography
            variant="body2"
            align="left"
            className={classes.primaryTextBlock}
            sx={{ color: color }}
          >
            <span>{category_name}</span>
          </Typography>
        </Box>
      </Grid>
      <Grid item sm={12}>
        <Typography variant="h6" className={classes.premium}>
          {title}
        </Typography>
      </Grid>
      <Grid item sm={12} sx={{ display: "flex" }}>
        <Avatar
          src={author_image}
          className={classes.headerAvatar}
          aria-haspopup="true"
          color="inherit"
        />
        <Box>
          <Typography
            variant="body2"
            align="left"
            className={classes.primaryText}
          >
            {author_name}
          </Typography>
          <Stack direction="row">
            <Typography variant="h6" className={classes.smallText}>
              {`${read_time} min read`}
            </Typography>
            {/* <Badge color="secondary" variant="dot"></Badge> */}
            <Typography variant="h6" className={classes.smallSubText}>
              {`പുസ്തകം ${book_number} - ${year_number}`}
            </Typography>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};

ArticleHeader.propTypes = {
  category_name: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string,
  author_name: PropTypes.string,
  author_image: PropTypes.string,
  book_number: PropTypes.string,
};
