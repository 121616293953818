import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { BreadCrumbList, NormalTextField } from "../../components";
import { contactus } from "../../assets";
import { styled } from "@mui/material/styles";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Container,
  Button,
  Box,
  Stack,
} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

//-------------------animation---------->
import Lottie from "lottie-react";
import { animationOtp } from "../../assets";
//-------------------animation---------->

import { api, urls } from "../../services";
import { useToast } from "../../contexts";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(0),
      paddingTop: theme.spacing(2.5),
    },
  },

  containerSecound: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingRight: theme.spacing(0),
    background: "#F6F6F6",
    minHeight: "70vh",
  },
  cardImage: {
    width: "40px",
    // padding: "0px",
    // borderRadius: "3px;"
  },
  cardImageXs: {
    width: "20px",
    marginRight: "12px",
  },
  sideCardContent: {
    "&.MuiCardContent-root": {
      padding: "0px",

      "&:last-child": {
        paddingBottom: "0px",
      },
    },
  },
  gridbt: {
    borderBottom: "1px solid #dedede",
  },
  cardIcon: {
    "&.MuiTypography-root": {
      fontSize: "16px",
      fontWeight: 700,
      color: "black",
    },
  },
  cardDesc: {
    "&.MuiTypography-root": {
      fontSize: "16px",
      fontWeight: 500,
      color: "#000000",
      [theme.breakpoints.down("md")]: {
        background: "#f2f2f2",
        padding: "13px",
        border: "1px solid #d7d7d7",
        borderRadius: "6px",
        marginTop: "0px !important",
      },
    },
  },
}));

//<!-----------------------sample Data------------------>

//---------------customised button --------->
const ColorButton = styled(Button)(({ theme }) => ({
  borderRadius: "8px",
  backgroundColor: "#EB363F",
  textTransform: "none",
  fontSize: 20,
  fontWeight: 700,
  marginTop: "30px !important",
  "&:hover": {
    backgroundColor: "#d33039",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 17,
    borderRadius: "10px",
  },
}));

export function FeedBack() {
  const classes = useStyles();

  //Modal ----------------------------------------------
  const [openModal, setOpenModal] = React.useState(false);
  const [feedback, setFeedback] = useState("");
  const [apiCalling, setApiCalling] = useState(false);
  const { showToast } = useToast();
  const navigate = useNavigate();

  const submitClick = () => {
    const params = { feedback: feedback };
    setApiCalling(true);
    api
      .post(urls.addFeedback, params)
      .then((res) => {
        showToast("Thank you for sharing your feedback", "success");
        setOpenModal(true);
        setApiCalling(false);
        setTimeout(function () {
          navigate("/home");
        }, 2000);
      })
      .catch((err) => {
        setApiCalling(false);
        showToast(err.response?.data?.message, "error");
      });
  };

  const handleClickOpen = () => {
    setOpenModal(true);
  };
  const handleClickClose = () => {
    setOpenModal(false);
  };
  //Modal ----------------------------------------------

  return (
    <>
      <Grid container className={classes.containerSecound}>
        <Container maxWidth="xl" className={classes.root}>
          <BreadCrumbList pathnames={[{ text: "Feedback", link: "" }]} />
          <Grid
            container
            className={classes.container}
            spacing={2}
            direction="row"
            sx={{ justifyContent: "center" }}
          >
            <Grid item xs={12} sm={12} md={8}>
              <Card sx={{ display: "flex" }}>
                <Grid
                  container
                  spacing={0}
                  direction={{ xs: "column", md: "row" }}
                >
                  <Grid
                    item
                    md={5}
                    sx={{ display: { xs: "none", md: "flex" } }}
                  >
                    <CardMedia
                      component="img"
                      sx={{ width: "100%", height: "100%" }}
                      image={contactus}
                      alt="contact us"
                    />
                  </Grid>
                  <Grid item md={7}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <CardContent sx={{ flex: "1 0 auto" }}>
                        <Stack
                          direction={{ xs: "column", md: "column" }}
                          spacing={{ xs: 2, sm: 1, md: 1 }}
                          padding={2}
                          marginBottom={10}
                        >
                          <Typography
                            variant="subtitle1"
                            color="text.secondary"
                            component="div"
                            className={classes.cardIcon}
                          >
                            Do you want to share your feedback?
                          </Typography>
                          <NormalTextField
                            id="comment"
                            required={true}
                            placeholder="Write here.."
                            rows={6}
                            value={feedback}
                            multiline={true}
                            onChange={(e) => setFeedback(e.target.value)}
                          />
                          <ColorButton
                            variant="contained"
                            disableElevation
                            fullWidth
                            disabled={feedback.trim() === "" || apiCalling}
                            onClick={submitClick}
                          >
                            Submit
                          </ColorButton>
                        </Stack>
                      </CardContent>
                    </Box>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Dialog open={openModal} onClose={handleClickClose}>
        <DialogContent>
          <Grid container spacing={2} direction="row">
            <Grid item xs={12}>
              <Box sx={{ height: "120px" }}>
                <Lottie
                  animationData={animationOtp}
                  loop={false}
                  style={{
                    width: 100,
                    height: 100,
                    margin: "auto",
                  }}
                />
              </Box>
              <Typography
                sx={{ fontSize: "14px", fontWeight: "900", color: "black" }}
              >
                Feedback Submitted
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
