import React from "react";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "&.MuiButton-root": {
      fontSize: 16,
      fontWeight: 600,
      color: "white",
      textTransform: "none",
      background: theme.palette.common.greenTxt,
      "&:hover": {
        background: theme.palette.common.greenTxt,
      },
      "&:disabled": {
        background: theme.palette.common.greyBg,
      },
      height: 50,
    },
  },
}));

export function PaymentButton(props) {
  const classes = useStyles();
  const { onClick, disabled } = props;

  return (
    <Button className={classes.root} onClick={onClick} disabled={disabled}>
      {props.children}
    </Button>
  );
}
