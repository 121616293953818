import { Container, Box, Grid, Typography, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { logo1, success } from "../../assets";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    "&.MuiContainer-root": {
      minHeight: "100vh",
    },
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(3),
    },
    // background: theme.palette.common.greyBg,
  },
  titleBar: {
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logoImage: {
    width: 220,
    height: 40,
  },
  statusContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  statusImage: {
    width: 200,
    height: 200,
  },
  titleText: {
    display: "flex",
    justifyContent: "center",
    "&.MuiTypography-root": {
      fontSize: 18,
      fontWeight: 500,
      color: "#303030",
    },
  },
}));

export function EmailVerified(props) {
  const classes = useStyles();
  const { title } = props;

  return (
    <Container maxWidth="md" className={classes.root} disableGutters>
      <Grid
        container
        className={classes.container}
        spacing={5}
        direction="column"
      >
        <Grid item>
          <Box className={classes.titleBar}>
            <img className={classes.logoImage} alt="logo" src={logo1} />
          </Box>
        </Grid>
        <Grid item sx={{ height: 100 }}></Grid>
        <Grid item>
          <Box className={classes.statusContainer}>
            <img className={classes.statusImage} alt="status" src={success} />
          </Box>
        </Grid>
        <Grid item>
          <Typography className={classes.titleText}>{title}</Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

EmailVerified.propTypes = {
  title: PropTypes.string,
};
