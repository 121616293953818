import React from "react";
import Lottie from "lottie-react";
import animationData from "../../assets/images/loader_two.json";
import { Box } from "@mui/material";

const LoaderCard = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ width: "350px" }}>
        <Lottie {...defaultOptions} />
      </Box>
    </Box>
  );
};

export default LoaderCard;
