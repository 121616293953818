import { createContext, useContext, useState, useEffect } from "react";
import { useLocalStorage } from "../hooks";

const AuthContext = createContext();

const AuthProvider = (props) => {
  const { getItem, removeItem, setItem } = useLocalStorage();
  const token = getItem("access_token");
  const user = getItem("user_data");

  const [isLoggedIn, setIsLoggedIn] = useState(Boolean(token));
  const [userData, setUserData] = useState(JSON.parse(user));

  const login = () => {
    // Perform login logic
    setIsLoggedIn(true);
  };

  const logout = () => {
    // Perform logout logic
    setIsLoggedIn(false);
    removeItem("access_token");
    removeItem("refresh_token");
    removeItem("user_data");
  };

  const updateName = (name) => {
    let data = JSON.parse(user);
    data.first_name = name;
    setUserData(data);
    setItem("user_data", JSON.stringify(data));
  };

  const updateUserData = (userData) => {
    setUserData(userData);
    setItem("user_data", JSON.stringify(userData));
    setIsLoggedIn(true);
  };

  useEffect(() => {
    setUserData(JSON.parse(user));
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        login,
        logout,
        userData,
        updateName,
        updateUserData,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
