import { TextField } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
      borderRadius: "8px",
      border: "0px solid #EAEAEA !important",
      background: "#F8F6F6",
    },
    notchedOutline: {
      borderWidth: "0px",
      borderColor: "#EAEAEA !important",
    },
  })
);

export function NormalTextField(props) {
  const classes = useStyles();
  const {
    id,
    label,
    multiline,
    onChange,
    type,
    value,
    error,
    required,
    rows,
    disabled,
  } = props;

  return (
    <TextField
      variant="outlined"
      InputProps={{
        classes: {
          notchedOutline: classes.notchedOutline,
        },
      }}
      className={classes.root}
      maxRows={3}
      rows={rows}
      id={id}
      label={label}
      multiline={multiline}
      onChange={onChange}
      type={type}
      value={value}
      error={error}
      required={required}
      disabled={disabled}
    />
  );
}
