import * as React from "react";
import PropTypes from "prop-types";

// material-ui
import { makeStyles, useTheme } from "@mui/styles";
import {
  Box,
  Item,
  Typography,
  useMediaQuery,
  Avatar,
  Paper,
} from "@mui/material";
import { VectorBottom, VectorTop } from "../../assets";
// style constant
const useStyles = makeStyles((theme) => ({
  boxItem: {
    padding: 24,
    borderRadius: 10,
    color: "white",
    display: "grid",
    gridTemplateColumns: { md: "1fr 1fr" },
    gap: 2,
    width: "fit-content",
    minWidth: "120px",
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "600",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100px",
      width: "100px",
      fontSize: "11px",
      padding: "24px 5px",
      textWrap: "wrap",
    },
  },
  VectorBottom: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "15%",
  },
  VectorTop: {
    position: "absolute",
    top: 0,
    right: 0,
    width: "15%",
  },
}));

export const CategoryCard = ({ primaryText, color, onItemClick }) => {
  const classes = useStyles();

  const theme = useTheme();
  const matchDownXs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Box
      className={classes.boxItem}
      sx={{
        backgroundColor: color,
        cursor: "pointer",
      }}
      onClick={onItemClick}
    >
      <img className={classes.VectorBottom} alt="image" src={VectorBottom} />
      <img className={classes.VectorTop} alt="image" src={VectorTop} />
      {primaryText}
    </Box>
  );
};

CategoryCard.propTypes = {
  primaryText: PropTypes.string,
  color: PropTypes.string,
  onItemClick: PropTypes.func,
};
