import { Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { TitleBar, BreadCrumbList } from "../../components";
const useStyles = makeStyles((theme) => ({
  heading: {
    "&.MuiTypography-root": {
      color: "#303030",
      fontSize: "18px",
      fontWeight: 600,
    },
  },
  subText: {
    "&.MuiTypography-root": {
      color: "#000000",
      fontSize: "16px",
      fontWeight: 400,
    },
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(0),
      paddingTop: theme.spacing(2.5),
    },
  },

  containerSecound: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingRight: theme.spacing(0),
    background: "#F6F6F6",
    minHeight: "70vh",
  },
}));

//<!-----------------------sample Data------------------>

export function PrivacyPolicy() {
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <Grid container className={classes.containerSecound}>
      <Container maxWidth="xl" className={classes.root}>
        <BreadCrumbList pathnames={[{ text: "Privacy Policy", link: "" }]} />
        <Grid
          container
          className={classes.container}
          spacing={2}
          direction="row"
        >
          <Grid item md={12} xs={12} sm={12}>
            <TitleBar Title="Privacy Policy" />
          </Grid>
          <Grid item md={12}>
            <iframe
              src="https://sunnivoice.com/privacy.html"
              title="Privacy Policy"
              width="100%"
              height="600"
              style={{ border: "none" }}
            ></iframe>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
