import * as React from "react";
import PropTypes from "prop-types";

// material-ui
import { makeStyles, useTheme } from "@mui/styles";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
// style constant
const useStyles = makeStyles((theme) => ({
  sideIconCard: {
    "&.MuiCard-root": {
      position: "relative",
      borderRadius: "6px",
      boxShadow: "none !important",
    },
  },
  sideCardContent: {
    "&.MuiCardContent-root": {
      "&:last-child": {
        padding: "10px !important",
        [theme.breakpoints.down("sm")]: {
          padding: "0px !important",
        },
      },
    },
  },
  primary: {
    "&.MuiTypography-root": {
      color: "#353535;",
      fontFamily: "Baloo Chettan 2;",
      fontSize: "20px;",
      fontStyle: "normal;",
      fontWeight: 500,
      lineHeight: "34px;",
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      },
    },
  },

}));

// =============================|| SIDE ICON CARD ||============================= //

export const ParagraphBox = ({ primary }) => {
  const classes = useStyles();

  const theme = useTheme();
  const matchDownXs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Card className={classes.sideIconCard}>
      <CardContent className={classes.sideCardContent}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.primary}>
              {primary}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ParagraphBox.propTypes = {
  primary: PropTypes.string,
};
