import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    lastItem: {
      color: "#929292",
      "&.MuiTypography-root": {
        fontSize: "13px",
      }
  
    },
    breadcrumbs: {
      "&.MuiBreadcrumbs-root": {
        fontSize: "13px",
      },
    },
    stack: {
      "&.MuiStack-root": {
          marginTop: '20px !important'
        }   
        },
    linkItem: {
      "&.MuiLink-root": {
        color: 'red'
        }
    },
  }));

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

export function BreadCrumb() {
  const classes = useStyles();
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/home" className={classes.linkItem}>
      Home
    </Link>,
    <Typography key="2" color="text.primary" className={classes.lastItem}>
      Trending Articles
    </Typography>,
  ];

  return (
    <Stack spacing={2} className={classes.stack}>
      <Breadcrumbs
        separator={<ArrowRightIcon fontSize="small" />}
        aria-label="breadcrumb"
        className = {classes.breadcrumbs}
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}