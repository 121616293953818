import { TextField, Autocomplete } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
    },
  })
);

export function DropdownTextField(props) {
  const classes = useStyles();
  const { id, label, onChange, options, value, error, required } = props;

  return (
    <Autocomplete
      id={id}
      className={classes.root}
      options={options}
      value={value}
      // renderTags={(value, getTagProps) =>
      //   value.map((option, index) => (
      //     <Chip variant="filled" label={option} {...getTagProps({ index })} />
      //   ))
      // }
      getOptionLabel={(option) => (option ? option.name : "")}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          required={required}
          error={error}
        />
      )}
      onChange={onChange}
    />
  );
}
