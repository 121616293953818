import * as React from "react";
import PropTypes from "prop-types";

// material-ui
import { makeStyles, useTheme } from "@mui/styles";
import {
  Card,
  CardContent,
  Grid,
  useMediaQuery,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import ReactPlayer from "react-player";

// style constant
const useStyles = makeStyles((theme) => ({
  sideIconCard: {
    "&.MuiCard-root": {
      position: "relative",
      borderRadius: "6px",
      boxShadow: "none !important",
    },
  },
  sideCardContent: {
    "&.MuiCardContent-root": {
      "&:last-child": {
        padding: "10px !important",
        [theme.breakpoints.down("sm")]: {
          padding: "0px !important",
        },
      },
    },
  },
  primary: {
    "&.MuiTypography-root": {
      color: "#353535;",
      fontFamily: "Baloo Chettan 2;",
      fontSize: "20px;",
      fontStyle: "normal;",
      fontWeight: 500,
      lineHeight: "34px;",
      marginTop: "20px",
      marginBottom: "20px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        marginBottom: "0px",
      },
    },
  },
  secondary: {
    "&.MuiTypography-root": {
      color: "#565656",
      fontFamily: "Baloo Chettan 2",
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "47px",
    },
  },
}));

// =============================|| SIDE ICON CARD ||============================= //

export const VideoArticle = ({ url, caption }) => {
  const classes = useStyles();

  const theme = useTheme();
  const matchDownXs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Card className={classes.sideIconCard}>
      <CardContent className={classes.sideCardContent}>
        <Grid container>
          <Grid item xs={12}>
            <Box
              sx={{
                width: "100%",
                height: { xs: "175px", sm: "360px" },
              }}
            >
              <Stack spacing={1}>
                <ReactPlayer
                  url={url}
                  controls={true}
                  width="100%"
                  height={"100%"}
                />
                <Typography
                  sx={{
                    fontSize: 16,
                    fontWeight: 400,
                    color: "#565656",
                    fontFamily: "Baloo Chettan 2",
                  }}
                >
                  {caption}
                </Typography>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

VideoArticle.propTypes = {
  url: PropTypes.string,
  primary: PropTypes.string,
  secondary: PropTypes.string,
};
