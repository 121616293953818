import * as React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
// material-ui
import { makeStyles, useTheme } from "@mui/styles";
import { Grid, Typography, Link, Stack, Box } from "@mui/material";
import { titleImage } from "../../assets";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
// style constant
const useStyles = makeStyles((theme) => ({
  titleText: {
    "&.MuiTypography-root": {
      fontSize: "32px",
      fontWeight: "600",
      paddingLeft: "15px",
      paddingRight: "10px",
      [theme.breakpoints.down("md")]: {
        fontSize: "18px",
      },
    },
  },
  verticalCenter: {
    alignItems: "center",
    display: "flex !important",
  },
  line: {
    height: 1,
    flex: 1,
    backgroundColor: "#D7D7D7",
    marginTop: "8px",
  },
  viewText: {
    "&.MuiLink-root": {
      fontWeight: "600",
      fontSize: "18px",
      marginLeft: "10px",
      color: "black",
      [theme.breakpoints.down("md")]: {
        fontSize: "15px",
      },
    },
  },
  boxImage: {
    display: "flex",
    alignItems: "center",
  },
  stackSection: {
    "&.MuiStack-root": {
      paddingBottom: "30px",
      [theme.breakpoints.down("md")]: {
        justifyContent: "space-between",
        paddingBottom: "10px",
      },
    },
  },
}));

// =============================|| SIDE ICON CARD ||============================= //

export const TitleBar = ({ Title, isViewMore, link }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Stack
      direction={{ xs: "row", sm: "row" }}
      className={classes.stackSection}
      alignItems={"center"}
    >
      <Box className={classes.boxImage}>
        <img
          className={classes.titleImage}
          alt="image"
          src={titleImage}
          width="18px"
        />
        <Typography className={classes.titleText}>{Title}</Typography>
      </Box>
      <Box
        className={classes.line}
        sx={{ display: { xs: "none", md: "flex" } }}
      />
      {isViewMore && (
        <>
          <Link
            href={link}
            underline="none"
            className={classes.viewText + " " + classes.verticalCenter}
          >
            View More
            <EastOutlinedIcon
              sx={{
                width: "25px",
                color: "#EB363F",
                paddingLeft: "5px",
              }}
            />
          </Link>
        </>
      )}
    </Stack>
  );
};

TitleBar.propTypes = {
  Title: PropTypes.string,
  isViewMore: PropTypes.bool,
  link: PropTypes.string,
};
