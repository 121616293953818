import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { makeStyles } from "@mui/styles";

import { Breadcrumbs as MUIBreadcrumbs } from "@mui/material";

import { useNavigate, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  lastItem: {
    color: "#929292",
    "&.MuiTypography-root": {
      fontSize: "14px",
      textTransform: "capitalize",
    },
  },
  breadcrumbs: {
    "&.MuiBreadcrumbs-root": {
      fontSize: "14px",
      padding: "32px 0px 0px 0px",
      [theme.breakpoints.down("md")]: {
        padding: "20px 0px 0px 10px",
      },
    },
  },
  stack: {
    "&.MuiStack-root": {
      marginTop: "20px !important",
    },
  },
  linkItem: {
    "&.MuiLink-root": {
      color: "#E91F29",
      textDecoration: "none",
      cursor: "pointer",
      textTransform: "capitalize",
    },
  },
}));

export function BreadCrumbList(props) {
  const { pathnames } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  console.log("pathname", pathnames);

  return (
    <MUIBreadcrumbs
      aria-label="breadcrumb"
      separator={<ArrowRightIcon fontSize="small" sx={{ color: "#E91F29" }} />}
      className={classes.breadcrumbs}
    >
      {pathnames.length ? (
        <Link onClick={() => navigate("/home")} className={classes.linkItem}>
          Home
        </Link>
      ) : (
        <Typography className={classes.lastItem}>Home</Typography>
      )}
      {pathnames.map((breadcrumb, index) => {
        const isLast = index === pathnames.length - 1;
        return isLast ? (
          <Typography className={classes.lastItem} key={index}>
            {breadcrumb.text}
          </Typography>
        ) : (
          <Link
            className={classes.linkItem}
            key={index}
            onClick={() => navigate(breadcrumb.link)}
          >
            {breadcrumb.text}
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
}
