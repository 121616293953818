import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export function EmailTextField(props) {
  const classes = useStyles();
  const { label, onChange, value, error, helperText } = props;

  return (
    <TextField
      id="email-field"
      variant="outlined"
      className={classes.root}
      label={label}
      onChange={onChange}
      value={value}
      error={error}
      helperText={helperText}
    />
  );
}

EmailTextField.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};
