import React from "react";
import { SignIn, SignInDetails, OtpVerification } from "../../pages";
import { AuthRoute } from "../../routes";

// ===========================|| AUTH ROUTING ||=========================== //

const LoginRoutes = {
  path: "/",
  element: "",
  children: [
    {
      path: "/signin",
      element: (
        <AuthRoute>
          <SignIn />
        </AuthRoute>
      ),
    },
    {
      path: "/signin-details",
      element: (
        <AuthRoute>
          <SignInDetails />
        </AuthRoute>
      ),
    },
    {
      path: "/otp-verification",
      element: (
        <AuthRoute>
          <OtpVerification />
        </AuthRoute>
      ),
    },
  ],
};

export default LoginRoutes;
