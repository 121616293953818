// API URLs
const urls = {
  // base url
  baseURL: {
    dev: "https://dev.sunnivoice.com/",
    live: "https://api.sunnivoice.com/",
  },

  // auth apis
  sendOTP: "api/auth/v1/send_otp",
  verifyOTP: "api/auth/v1/verify_otp",
  RegisterUser: "api/auth/v1/register_user",
  refreshToken: "api/auth/v1/refresh_token",

  // main apis
  dashboard: "api/magazine/v1/main_dashboard",
  magazine: "api/magazine/v1/magazine_list?page=1&limit=100",
  trendingArticles: "api/magazine/v1/trending_article_list?page=1&limit=100",
  topArticles: "api/magazine/v1/top_articles_list?page=1&limit=100",
  featuredArticles: "api/magazine/v1/featured_article_list?page=1&limit=100",
  bookmarkedArticles:
    "api/magazine/v1/bookmarked_articles_list?page=1&limit=100",
  bookmark: "api/magazine/v1/bookmark",
  magazineDetails: "api/magazine/v1/magazine_details/",
  addFeedback: "api/magazine/v1/add_feedback",
  articleDetails: "api/magazine/v1/article_details/",
  categories: "api/magazine/v1/category_list?page=1&limit=100",
  plans: "api/magazine/v1/subscription_plan_list",
  categoryDetails: "api/magazine/v1/category_articles_list/",
  activePlans: "api/magazine/v1/active_plans",
  notifications: "api/magazine/v1/notification_list?page=1&limit=10",
  editProfile: "api/auth/v1/profile_edit",
  addClaps: "api/magazine/v1/add_article_claps/",
  removeClaps: "api/magazine/v1/remove_article_claps/",
  searchArticle: "api/magazine/v1/search_articles_list",
  guestDashboard: "api/magazine/v1/dashboard",
};

export default urls;
