import { Paper, Typography, Stack, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { tick } from "../../assets";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    "&.MuiPaper-root": {
      background: theme.palette.common.blackBg,
      padding: theme.spacing(3),
      borderRadius: theme.spacing(1),
    },
  },
  container: {
    display: "flex",
    justifyContent: "center",
  },
  tickImage: {
    width: 25,
    height: 25,
  },
  boldText: {
    "&.MuiTypography-root": {
      fontSize: 24,
      fontWeight: 700,
      color: theme.palette.common.white,
    },
  },
  mediumText: {
    "&.MuiTypography-root": {
      fontSize: 16,
      fontWeight: 500,
      color: theme.palette.common.white,
    },
  },
  dateText: {
    "&.MuiTypography-root": {
      fontSize: 14,
      fontWeight: 400,
      color: theme.palette.common.lightGreyTxt,
    },
  },
  tickContainer: {
    display: "flex",
    alignItems: "center",
  },
}));

const getDuration = (days) => {
  switch (days) {
    case 365:
      return "Year";
    case 180:
      return "Half Year";
    case 30:
      return "Month";
    default:
      return "-";
  }
};

export function SelectedPlanCard(props) {
  const classes = useStyles();
  const { price, days, name, startDate } = props;

  const getDateRange = () => {
    if (startDate) {
      let start = moment(startDate, "YYYY-MM-DD");
      let end = start.clone().add(days, "days");
      return `From ${start.format("DD MMM YYYY")} to ${end.format(
        "DD MMM YYYY"
      )}`;
    } else {
    }
  };

  return (
    <Paper className={classes.root}>
      <Grid container direction="row">
        <Grid item xs>
          <Stack spacing={1}>
            <Stack direction="row" alignItems="center">
              <Typography
                className={classes.boldText}
              >{`₹ ${price}/`}</Typography>
              <Typography className={classes.mediumText}>
                {getDuration(days)}
              </Typography>
            </Stack>
            <Typography className={classes.mediumText}>{name}</Typography>
            <Typography className={classes.dateText}>
              {getDateRange()}
            </Typography>
          </Stack>
        </Grid>
        <Grid item className={classes.tickContainer}>
          <img className={classes.tickImage} alt="tick" src={tick} />
        </Grid>
      </Grid>
    </Paper>
  );
}
