import React from "react";
import { Paper, Typography, Stack, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { tick, magazine1 } from "../../assets";

const useStyles = makeStyles((theme) => ({
  root: {
    "&.MuiPaper-root": {
      padding: theme.spacing(3),
      borderRadius: theme.spacing(1),
    },
  },
  container: {
    display: "flex",
    justifyContent: "center",
  },
  magImage: {
    width: 40,
    height: 60,
  },
  boldText: {
    "&.MuiTypography-root": {
      fontSize: 14,
      fontWeight: 700,
    },
  },
  redText: {
    "&.MuiTypography-root": {
      fontSize: 13,
      fontWeight: 400,
      color: theme.palette.common.redTxt,
    },
  },
  regularText: {
    "&.MuiTypography-root": {
      fontSize: 16,
      fontWeight: 400,
    },
  },
  dateText: {
    "&.MuiTypography-root": {
      fontSize: 14,
      fontWeight: 400,
      color: theme.palette.common.lightGreyTxt,
    },
  },
  tickContainer: {
    display: "flex",
    alignItems: "center",
  },
}));

export function SubMagazineCard(props) {
  const classes = useStyles();
  const { magazine } = props;

  return (
    <Paper className={classes.root}>
      <Grid container direction="row">
        <Grid item xs>
          <Stack spacing={1}>
            <Typography className={classes.regularText}>
              Subscription starts with this Magazine
            </Typography>
            <Stack spacing={3} direction="row">
              <img
                className={classes.magImage}
                alt="img"
                src={magazine.cover_image}
              />
              <Stack justifyContent="center">
                <Typography className={classes.boldText}>
                  {magazine.issue_name}
                </Typography>
                <Typography
                  className={classes.redText}
                >{`Book ${magazine.book_number}`}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
}
