import React, { useState, useEffect, Fragment } from "react";
import {
  Container,
  Grid,
  CircularProgress,
  Box,
  Stack,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  ParagraphBox,
  BreadCrumbList,
  HighlightedQuote,
  Quote,
  VideoArticle,
  Audio,
  Advertisement,
  DownloadBtn,
  ArticleHeader,
  ArticleImage,
  SubTitle,
} from "../../components";
import Divider from "@mui/material/Divider";
import { useParams } from "react-router-dom";
import { api, urls } from "../../services";
import { useToast, useAuth } from "../../contexts";
import { FloatingArticleButtons } from "../../components/FloatingArticleButtons";
import LoaderCard from "../../components/cards/LoaderCard";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(2),
    minHeight: "66vh",
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(0),
      paddingTop: theme.spacing(2.5),
      minHeight: "0px",
    },
  },
  containerSecound: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingRight: theme.spacing(0),
    background: "#F6F6F6",
    // minHeight: "70vh",
  },
  divider: {
    "&.MuiDivider-root": {
      marginTop: "20px",
      marginBottom: "20px",
    },
  },
}));

//<!-----------------------sample Data------------------>

export function ArticleDetails() {
  const classes = useStyles();
  const { artID } = useParams();
  const [detailsData, setDetailsData] = useState(null);
  const [bookmarkData, setBookmarkData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { showToast } = useToast();
  const { logout } = useAuth();
  const theme = useTheme();

  const getArticleDetails = () => {
    setIsLoading(true);
    api
      .get(urls.articleDetails + artID)
      .then((res) => {
        setIsLoading(false);
        setDetailsData(res.data.data);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const ClickBookmark = () => {
    setBookmarkData((current) => !current);
    console.log("bookmarkData1", bookmarkData);

    if (bookmarkData) {
      const params = { article_id: artID };
      delete params.errors;
      console.log("params", params);
      api
        .post(urls.bookmark, params)
        .then((res) => {
          showToast(res.data.message, "success");
        })
        .catch((err) => {
          showToast(err.response?.data?.message, "error");
        });
    }
  };
  useEffect(() => {
    getArticleDetails();
    setBookmarkData(detailsData?.bookmark);
  }, []);

  const handleBookmarkClick = () => {
    api
      .post(urls.bookmark + `/${artID}`)
      .then((res) => {
        setDetailsData((prev) => ({ ...prev, bookmark: !prev.bookmark }));
      })
      .catch((err) => {
        showToast(err.response?.data?.message, "error");
      });
  };

  const handleShareClick = () => {
    navigator.clipboard.writeText(window.location.href);
    showToast("Link copied to clipboard");
  };

  const handleClapsClick = (claps) => {
    let url = "";
    if (claps > 0) {
      url = urls.addClaps + artID;
    } else {
      url = urls.removeClaps + artID;
    }
    api
      .post(url, { claps: claps })
      .then((res) => {
        getArticleDetails();
      })
      .catch((err) => {
        showToast(err.response?.data?.message, "error");
      });
  };

  return (
    <Fragment>
      <Box>
        <Container maxWidth="xl" className={classes.root}>
          <BreadCrumbList
            pathnames={[
              { text: "Articles", link: "/articles" },
              { text: "Article details", link: "" },
            ]}
          />
          {isLoading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "450px",
                minHeight: "66vh",
              }}
            >
              <LoaderCard />
            </Box>
          )}
          {!isLoading && (
            <Grid
              container
              className={classes.container}
              spacing={2}
              direction="row"
            >
              <Grid item md={12}>
                <Stack spacing={1}>
                  <ArticleHeader
                    category_name={
                      detailsData?.article_categories[0].descriptions[0]
                        .category_name
                    }
                    color={detailsData?.article_categories[0].category_colour}
                    title={detailsData?.title}
                    author_name={detailsData?.author_name}
                    author_image={detailsData?.author_image}
                    book_number={detailsData?.magazine?.book_number}
                    year_number={detailsData?.magazine?.year_number}
                    read_time={detailsData?.components[2]?.author?.read_time}
                  />
                  {/* <Divider /> */}
                  {detailsData?.components?.map((post, i) => {
                    return (
                      <>
                        {post.type_name === "Image" &&
                          (post.is_free === 1 ||
                            detailsData.is_free == 1 ||
                            detailsData.magazine.is_free == 1 ||
                            detailsData.magazine.purchased == 1) && (
                            <ArticleImage
                              feature_image={post?.url}
                              // onClickBookmark={ClickBookmark}
                              caption={post?.caption_text}
                            />
                          )}
                        {post.type_name === "Video" &&
                          (post.is_free === 1 ||
                            detailsData.is_free == 1 ||
                            detailsData.magazine.is_free == 1 ||
                            detailsData.magazine.purchased == 1) && (
                            <VideoArticle
                              url={post.url}
                              caption={post?.caption_text}
                            />
                          )}
                        {post.type_name === "Divider" &&
                          (post.is_free === 1 ||
                            detailsData.is_free == 1 ||
                            detailsData.magazine.is_free == 1 ||
                            detailsData.magazine.purchased == 1) && (
                            <Divider className={classes.divider} />
                          )}
                        {post.type_name === "Advertisement" && (
                          <Advertisement
                            image={detailsData.advertisement?.image_url}
                            title={detailsData.advertisement?.title}
                            url={detailsData.advertisement?.url}
                          />
                        )}
                        {post.type_name === "Audio" &&
                          (post.is_free === 1 ||
                            detailsData.is_free == 1 ||
                            detailsData.magazine.is_free == 1 ||
                            detailsData.magazine.purchased == 1) && (
                            <Audio url={post.url} author={post.author} />
                          )}
                        {post.type_name === "Highlighted Quote" &&
                          post.text !== "" &&
                          (post.is_free === 1 ||
                            detailsData.is_free == 1 ||
                            detailsData.magazine.is_free == 1 ||
                            detailsData.magazine.purchased == 1) && (
                            <HighlightedQuote primary={post.text} />
                          )}
                        {post.type_name === "Quote" &&
                          post.main_text !== "" &&
                          (post.is_free === 1 ||
                            detailsData.is_free == 1 ||
                            detailsData.magazine.is_free == 1 ||
                            detailsData.magazine.purchased == 1) && (
                            <Quote
                              secondary={post.pre_text}
                              primary={post.main_text}
                              postPrimary={post.post_text}
                            />
                          )}
                        {post.type_name === "Paragraph" &&
                          (post.is_free === 1 ||
                            detailsData.is_free == 1 ||
                            detailsData.magazine.is_free == 1 ||
                            detailsData.magazine.purchased == 1) && (
                            <ParagraphBox primary={post.text} />
                          )}
                        {post.type_name === "Sub Title" &&
                          (post.is_free === 1 ||
                            detailsData.is_free == 1 ||
                            detailsData.magazine.is_free == 1 ||
                            detailsData.magazine.purchased == 1) && (
                            <SubTitle secondary={post.text} />
                          )}
                        {post.type_name === "Link" &&
                          (post.is_free === 1 ||
                            detailsData.is_free == 1 ||
                            detailsData.magazine.is_free == 1 ||
                            detailsData.magazine.purchased == 1) && (
                            <DownloadBtn
                              caption_text={post.caption_text}
                              url={post.url}
                            />
                          )}
                      </>
                    );
                  })}
                </Stack>
              </Grid>
            </Grid>
          )}
        </Container>
      </Box>
      <FloatingArticleButtons
        clapsCount={detailsData?.claps_count}
        userClapsCount={detailsData?.user_claps_count}
        isBookMarked={detailsData?.bookmark}
        onBookmarkClick={handleBookmarkClick}
        onShareClick={handleShareClick}
        onClapClick={handleClapsClick}
      />
    </Fragment>
  );
}
