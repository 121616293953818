import { makeStyles } from "@mui/styles";
import { Grid, Container } from "@mui/material";
import Card from '@mui/material/Card';

const useStyles = makeStyles((theme) => ({
  verticalCenter: {
    minHeight: "100vh",
    display: "flex !important",
    alignItems: "center"
  },
  card: {
    borderRadius: "12px !important",
    boxShadow: "none !important",
  }
}));
export function AuthCard(props) {
  const classes = useStyles();
  return (
    <Grid sx={{  backgroundColor: '#F6F6F6' }}>
    <Container maxWidth="md" className={classes.verticalCenter}>
      <Grid
        container
        className={classes.container}
        direction="column"
      >
        <Card className={classes.card} sx={{ display: 'flex' }}>
          {props.children}
        </Card>
      </Grid>
    </Container>
    </Grid>
  )
}
