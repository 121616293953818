import { Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TitleBar, PlansCard, BreadCrumbList } from "../../components";
import { recImage } from "../../assets";
import { useState } from "react";
import { urls, api } from "../../services";
import { useEffect } from "react";
import { useToast, useAuth } from "../../contexts";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(0),
      paddingTop: theme.spacing(2.5),
    },
  },

  containerSecound: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingRight: theme.spacing(0),
    background: "#F6F6F6",
    minHeight: "70vh",
  },
}));

//<!-----------------------sample Data------------------>

export function Plans() {
  const { showToast } = useToast();
  const [plans, setPlans] = useState([]);
  const { logout, isLoggedIn, userData } = useAuth();

  const getPlans = () => {
    console.log("User Data", userData);
    let url = urls.plans;
    if (isLoggedIn) {
      url += `/${userData.id}`;
    }
    api
      .get(url)
      .then((res) => setPlans(res.data.data))
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };
  useEffect(() => {
    getPlans();
  }, []);

  const classes = useStyles();

  const getDuration = (days) => {
    switch (days) {
      case 365:
        return "Year";
      case 180:
        return "Half Year";
      case 30:
        return "Month";
      default:
        return "-";
    }
  };

  function onSubscribeClick() {
    window.open(`/plans/${userData.id}`, "_blank");
  }

  return (
    <Grid container className={classes.containerSecound}>
      <Container maxWidth="xl" className={classes.root}>
        <BreadCrumbList pathnames={[{ text: "Plans", link: "" }]} />
        <Grid
          container
          className={classes.container}
          spacing={2}
          direction="row"
        >
          <Grid item xs={12} sm={12} md={12}>
            <TitleBar Title="Plans" />
          </Grid>
          {plans.map((item) => (
            <Grid item xs={12} md={3}>
              <PlansCard
                category={item.subscriptions[0].product_set_name}
                price={item.actual_amount}
                disableButton={
                  !isLoggedIn || item.subscriptions[0].is_subscribed
                }
                duration={getDuration(item.subscriptions[0].duration)}
                onSubscribeClick={onSubscribeClick}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Grid>
  );
}
